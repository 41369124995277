import {makeRequest} from "../../RestAdapter";
import {HttpMethodsEnum} from "../../HttpMethodsEnum";
import {ProductType, UpdateProductType} from "../../../types/ProductType";
import {GetProductResponseType} from "../../../types/GetProductResponseType";
import {showFailNotification, showSuccessNotification} from "../../../utils/NotificationUtils";
import {CategoryType} from "../../../types/CategoryType";


export function addProduct(product: ProductType, onFinish: (response: any[]) => void) {
    var tmpReq = product
    tmpReq.photoPath = tmpReq.photoPath.split(',')[1];


    tmpReq.ingredients.map((it)=>(
        // @ts-ignore
        it.position=Object.fromEntries(Array.from(it.position))
    ))
    // @ts-ignore
    tmpReq.position=Object.fromEntries(Array.from(tmpReq.position))
    makeRequest<any[]>(`/restaurants/products`, HttpMethodsEnum.POST, tmpReq).then((res) => {
        if (res.data !== null && (res.status === 201 || res.status === 200)) {
            showSuccessNotification('Prodotto', 'Aggiunto con successo', 1000)
            onFinish(res.data)
        } else {
            showFailNotification('Prodotti', 'Errore durante la creazione', 1500)
            onFinish([])
        }
    })
}

export function updateProduct(idProduct: string, product: UpdateProductType, onFinish: (response: GetProductResponseType[]) => void) {
    var tmpReq = product
    tmpReq.photo = tmpReq.photo.split(',')[1]
    tmpReq.ingredients.map((it)=>(
        // @ts-ignore
        it.position=Object.fromEntries(Array.from(it.position))
    ))
    makeRequest<any[]>(`/restaurants/products/update/${idProduct}`, HttpMethodsEnum.PUT, tmpReq).then((res) => {
        if (res.isSuccessfull()) {
            showSuccessNotification('Prodotto', 'Modificato con successo', 1000)
            onFinish(res.data?res.data:[])
        }else{
            showFailNotification('Prodotti', 'Errore durante la modifica', 1500)
            onFinish([])
        }
    })
}

export function changeProductStatus(idProduct: string, isAvailable: boolean, onFinish: (response: any[]) => void) {
    makeRequest<any[]>(`/restaurants/products/availability/${idProduct}?isAvailable=${isAvailable}`, HttpMethodsEnum.PUT).then((res) => {
        if (res.data !== null) {
            onFinish(res.data)
        }
    })
}


export function getAllProductsFromIdLocalAndIdMenu(onFinish: (response: Map<string, GetProductResponseType[]>) => void) {
    makeRequest<Map<string, GetProductResponseType[]>>(`/restaurants/products/local/${JSON.parse(localStorage.getItem('selectedRestaurant') as string).id}/${JSON.parse(localStorage.getItem('selectedMenu') as string).id}`, HttpMethodsEnum.GET).then((res) => {
        if (res.isSuccessfull()) {
            onFinish(res.data ? res.data : new Map<string, GetProductResponseType[]>())
        }else{
            onFinish(new Map<string, GetProductResponseType[]>())
        }
    })
}


export function getProductAdminSelect(onFinish: (response: GetProductResponseType[]) => void) {
    makeRequest<GetProductResponseType[]>(`/restaurants/products/getProductAdminSelect/${JSON.parse(localStorage.getItem('selectedRestaurant') as string).id}/${JSON.parse(localStorage.getItem('selectedMenu') as string).id}`, HttpMethodsEnum.GET).then((res) => {
        if (res.isSuccessfull()) {
            onFinish(res.data ? res.data : [])
        }
    })
}

export function getCategoryNotPresent(onFinish: (response: CategoryType[]) => void) {
    makeRequest<CategoryType[]>(`/restaurants/categories/local/get-category-no-present-menu/${JSON.parse(localStorage.getItem('selectedRestaurant') as string).id}/${JSON.parse(localStorage.getItem('selectedMenu') as string).id}`, HttpMethodsEnum.GET).then((res) => {
        if (res.data !== null) {
            onFinish(res.data)
        }
    })
}

export function addListProdCategoryMenu(request: {
    listProdId: string[],
    idCategory: string,
    idMenu: string
}, onFinish: (response: GetProductResponseType[]) => void) {
    makeRequest<any[]>(`/restaurants/products/addListProdToCategoryMenu`, HttpMethodsEnum.PUT, request).then((res) => {
        if (res.isSuccessfull()) {
            onFinish(res.data ? res.data : [])
        }
    })
}


export function updateProductPosition(request: GetProductResponseType[], onFinish: (response: GetProductResponseType[]) => void) {
    var tmpReq= request
    makeRequest<any[]>(`/restaurants/products/updatePositionProduct`, HttpMethodsEnum.PUT, {listProd:tmpReq}).then((res) => {
        if (res.isSuccessfull()) {
            onFinish(res.data ? res.data : [])
        }else{

        }
    })
}

export function deleteProducts(idProduct:string,idCategory:string, onFinish: (response: any) => void) {
    makeRequest<any>(`/restaurants/products/delete/${JSON.parse(localStorage.getItem('selectedMenu') as string).id}/${idProduct}/${idCategory}`, HttpMethodsEnum.DELETE).then((res) => {
        if (res.isSuccessfull()) {
            showSuccessNotification('Prodotto','Rimosso dalla categoria con successo',3000)
            onFinish(res)
        }
    })
}

export function deleteProductFromCategory(idProduct:string,idCategory:string, onFinish: (response: any) => void) {
    makeRequest<any>(`/restaurants/products/deleteProductFromCategory/${JSON.parse(localStorage.getItem('selectedMenu') as string).id}/${idProduct}/${idCategory}`, HttpMethodsEnum.DELETE).then((res) => {
        if (res.isSuccessfull()) {
            showSuccessNotification('Prodotto','Rimosso dalla categoria con successo',3000)
            onFinish(res)
        }
    })
}
