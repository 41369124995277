import React, { useEffect, useRef, useState } from 'react';
import ChatInput from './ChatInput';
import ChatMessage from './ChatMessage';
import { sendMessageToLex } from '../../services/lexService';
import { Divider } from "@mantine/core";
import chefIcon from "../../assets/chef.svg";
import reloadIcon from "../../assets/newChatIcon.png";
import closeIcon from "../../assets/closeIcon.png";
import { isMobile } from "react-device-detect";
import { v4 as uuidv4 } from 'uuid';
import GenericTooltip from "../generic/GenericTooltip";
import ChatCard from "./ChatCard";
import ChatCardReservationRecap from "./ChatCardReservationRecap";

interface CardButton {
    text: string;
    value: string;
}

export interface ImageResponseCard {
    imageUrl: string;
    title: string;
    subtitle: string;
    buttons?: CardButton[];
}

interface Message {
    text: string;
    isUser: boolean;
    cardData?: ImageResponseCard;
}

export default function ChatWindow(props: { closeChat: () => void }) {
    const [messages, setMessages] = useState<Message[]>(() => {
        const savedMessages = localStorage.getItem('chatMessages');
        if (savedMessages) {
            return JSON.parse(savedMessages).map((msg: any) => ({
                ...msg,
                cardData: msg.cardData ? {
                    ...msg.cardData,
                    buttons: msg.cardData.buttons || []
                } : undefined,
            }));
        }
        return [];
    });

    const [sessionId, setSessionId] = useState<string>(() => {
        const savedSessionId = localStorage.getItem('chatSessionId');
        return savedSessionId || uuidv4();
    });

    const endOfMessagesRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // Add initial message if no messages exist
        if (messages.length === 0) {
            const initialMessage: Message = {
                text: 'Ciao! Sono\n' +
                    'ChefBot\n' +
                    ', il tuo assistente virtuale per prenotare un tavolo o trovare il miglior locale dove mangiare. Vuoi prenotare subito un tavolo o preferisci cercare un ristorante nella tua zona? Fammi sapere come posso aiutarti! 🍽️😊',
                isUser: false
            };
            setMessages([initialMessage]);
        }
    }, [messages.length]);

    const handleSend = async (message: string) => {
        const userMessage: Message = {
            text: message.includes('Prenotazione/') ? message.split('/')[0] : message,
            isUser: true
        };
        setMessages(prevMessages => [...prevMessages, userMessage]);
        const response = await sendMessageToLex(message, sessionId);
        const lexMessage = response.messages?.[0].content;
        const cardData = response.messages?.filter((msg) => msg.contentType === "ImageResponseCard").map((msg) => msg.imageResponseCard);
        const newMessages: Message[] = [];
        if (lexMessage) {
            newMessages.push({
                text: lexMessage,
                isUser: false
            });
        }

        if (cardData && cardData.length > 0) {
            cardData.forEach((card) => {
                if (card) {
                    newMessages.push({
                        text: '', // Card content is managed separately
                        isUser: false,
                        // @ts-ignore
                        cardData: card
                    });
                }
            });
        }

        setMessages(prevMessages => [...prevMessages, ...newMessages]);
    };

    const resetChat = () => {
        const newSessionId = uuidv4();
        setSessionId(newSessionId);
        localStorage.setItem('chatSessionId', newSessionId);
        setMessages([]);
        localStorage.removeItem('chatMessages');
    };

    useEffect(() => {
        localStorage.setItem('chatSessionId', sessionId);
    }, [sessionId]);

    useEffect(() => {
        const saveMessagesToLocalStorage = () => {
            const messagesJSON = JSON.stringify(messages.map(message => ({
                ...message,
                cardData: message.cardData ? {
                    ...message.cardData,
                    buttons: message.cardData.buttons || []
                } : undefined,
            })));
            localStorage.setItem('chatMessages', messagesJSON);
        };

        saveMessagesToLocalStorage();
    }, [messages]);

    useEffect(() => {
        if (endOfMessagesRef.current) {
            endOfMessagesRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    const renderMessage = (message: Message, idx: number) => (
        <React.Fragment key={idx}>
            {message.text && (
                <ChatMessage message={<p className={'font-poppins-regular'}>{message.text}</p>}
                             isUser={message.isUser} />
            )}
            {message.cardData ? message.cardData.title.split('Cg1!').length > 1 ?
                <ChatCardReservationRecap cardData={message.cardData} onClose={props.closeChat} onSend={(value) => {
                    handleSend(value);
                }} /> :
                <ChatCard onLocalNoReservation={()=>{
                    if (messages[messages.length-1].text!=='Spero di averti aiutato! Posso fare altro per te?'){
                        setMessages(prevMessages => [...prevMessages, {
                            text: 'Spero di averti aiutato! Posso fare altro per te?',
                            isUser: false
                        }]);
                    }
                }} cardData={message.cardData} onClose={props.closeChat} onSend={(value) => {
                    handleSend(value);
                }} /> : null
            }
        </React.Fragment>
    );

    const renderChatWindow = () => (
        <div className="flex flex-col h-full">
            <div className="flex bg-yellow w-full h-[15%] items-center rounded-t-2xl p-4 gap-x-4">
                <img className="w-[35px] h-[35px]" src={chefIcon} alt="Chef Icon" />
                <p className="w-full font-poppins-bold whitespace-nowrap text-lg">Assistente ChefBot</p>
                <div className="w-full flex justify-end items-center gap-2">
                    <GenericTooltip txt={'Avvia nuova Conversazione con ChefBot!'} innerHtml={
                        <img
                            src={reloadIcon}
                            alt="Reload"
                            className="cursor-pointer w-6 h-6 hover:scale-125 duration-500"
                            onClick={resetChat}
                        />
                    } />
                    <img
                        src={closeIcon}
                        alt=""
                        className="cursor-pointer w-5 h-5 hover:scale-125 duration-500"
                        onClick={props.closeChat}
                    />
                </div>
            </div>
            <Divider color="dark" size="md" />
            <div className="flex-grow p-4 overflow-y-auto">
                {messages.map((msg, idx) => renderMessage(msg, idx))}
                <div ref={endOfMessagesRef} />
            </div>
            <Divider color="black" size="xs" />
            <ChatInput onSend={handleSend} />
        </div>
    );

    return isMobile ? (
        <div
            className="fixed bottom-4 left-1/2 transform -translate-x-1/2 w-[calc(100%-2rem)] h-[60%] bg-gray-100 mb-32 shadow-2xl shadow-black z-50 rounded-2xl mx-auto">
            {renderChatWindow()}
        </div>
    ) : (
        <div
            className="fixed bottom-4 right-4 w-[450px] h-[600px] bg-gray-100 mb-36 shadow-2xl shadow-black z-50 rounded-2xl">
            {renderChatWindow()}
        </div>
    );
}
