import {PaymentElement} from "@stripe/react-stripe-js";
import {useEffect, useState} from "react";
import {useStripe, useElements} from "@stripe/react-stripe-js";
import {PrinterShopType} from "../types/PrinterType";
import {Buffer} from "buffer";

import {getRestaurants} from "../network/controllers/admin/AdminController";
import {GetRestaurantResponseType} from "../types/GetRestaurantResponseType";
import {Divider, Select, Switch} from "@mantine/core";
import GoogleAddressSearch from "./GoogleAddressSearch";
import CustomTextInput from "./generic/CustomTextInput";
import {LoginResponseType} from "../types/LoginResponseType";
import CustomButton from "./CustomButton";
import {showFailNotification} from "../utils/NotificationUtils";

export default function CheckoutFormShop(props: { printer: PrinterShopType, idStripe: string }) {
    const stripe = useStripe();
    const elements = useElements();

    const [message, setMessage] = useState<string | null>(null);
    const [isProcessing, setIsProcessing] = useState(false);

    const [restaurantList, setRestaurantList] = useState<{ label: string, value: any }[]>([]);
    const [selectedRestaurant, setSelectedRestaurant] = useState<GetRestaurantResponseType>()
    const [user, setUser] = useState<LoginResponseType>(JSON.parse(localStorage.getItem("userData") as string));
    const [customShipping, setCustomShipping] = useState(false);
    const [dataToSend, setDataToSend] = useState({
        printerData: props.printer,
        datiDiFatturazione: {
            nome: user.name,
            cognome: user.surname,
            note: '',
            email: user.email,
            telefono: user.phone,
            indirizzo: selectedRestaurant?.address,
            sdi_pec: selectedRestaurant?.legalForm,
            piva: selectedRestaurant?.vatNumber,
            cf: selectedRestaurant?.vatNumber,
            nomeAttivita: selectedRestaurant?.companyName
        },
        datiDiSpedizione: {
            indirizzo: '',
            nome: '',
            cognome: '',
            note: ''
        }
    });

    useEffect(() => {
        getRestaurants((list) => {
            var tmpArr: { label: string, value: any }[] = [];
            list.forEach((restaurant) => {
                tmpArr.push({value: JSON.stringify(restaurant), label: restaurant.nameRestaurant});
            })
            setRestaurantList(tmpArr)
        })
    }, []);

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        if (!stripe || !elements) {
            return;
        }
        setIsProcessing(true);
        localStorage.setItem("shopData", Buffer.from(JSON.stringify(dataToSend)).toString('base64'));
        const {error} = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `${process.env['REACT_APP_REDIRECT_STRIPE'] as string}/resultShop?idStripe=${props.idStripe}`,
            },
        });

        if (error) {
            // @ts-ignore
            showFailNotification('Errore', error.message, 3000);
            setIsProcessing(false); // Reset isProcessing state if there's an error
            localStorage.removeItem("shopData")
        }
    };

    useEffect(() => {
        console.log(dataToSend)
    }, [dataToSend]);
    return (
        <div className={'w-full'}>
            <p className={'font-poppins-bold text-2xl text-amber-400 mt-14'}>Seleziona Ristorante</p>
            <div className={'w-full h-[50px] flex '}>
                <Select
                    variant={'unstyled'}
                    value={JSON.stringify(selectedRestaurant)}
                    data={restaurantList}
                    classNames={{
                        root: 'w-full mt-2 ml-1',
                        wrapper: 'w-full h-full',
                        input: 'w-full h-full px-5 bg-[#F2F2F2A8] rounded-lg',
                    }}
                    onChange={(value) => {
                        if (value) {
                            setSelectedRestaurant(JSON.parse(value) as GetRestaurantResponseType)
                            setDataToSend({
                                ...dataToSend, datiDiFatturazione: {
                                    nome: user.name,
                                    cognome: user.surname,
                                    note: '',
                                    email: user.email,
                                    telefono: user.phone,
                                    indirizzo: (JSON.parse(value!!) as GetRestaurantResponseType).address,
                                    sdi_pec: (JSON.parse(value!!) as GetRestaurantResponseType).legalForm,
                                    piva: (JSON.parse(value!!) as GetRestaurantResponseType).vatNumber,
                                    cf: (JSON.parse(value!!) as GetRestaurantResponseType).vatNumber,
                                    nomeAttivita: (JSON.parse(value!!) as GetRestaurantResponseType).companyName
                                }
                            })
                        }
                    }}
                />
            </div>
            {selectedRestaurant ?
                <div className={'w-full flex flex-col mt-8 gap-y-4'}>
                    <CustomTextInput white={true} label={"Nome Stampante"} readOnly={true}
                                     defaultValue={dataToSend.printerData.name}/>
                    <div className={'w-full flex h-auto gap-x-4'}>
                        <CustomTextInput white={true} customDimensions={'w-full h-[40px]'} readOnly={true}
                                         label={"Tipo Acquisto"}
                                         defaultValue={dataToSend.printerData.typePurchase === 'PURCHASE' ? 'Acquisto' : 'Noleggio'}/>
                        <CustomTextInput white={true} customDimensions={'w-full h-[40px]'} readOnly={true}
                                         label={"Prezzo"} defaultValue={`${props.printer.price} €`}/>
                    </div>
                    <p className={' font-poppins-bold text-2xl text-amber-400 mt-10'}>Dati Utente</p>
                    <div className={'w-full h-auto flex gap-x-4  mb-4'}>
                        <CustomTextInput white={true} customDimensions={'w-full h-[40px]'} onChange={(value) => {
                            setDataToSend({
                                ...dataToSend,
                                datiDiFatturazione: {...dataToSend.datiDiFatturazione, nome: value}
                            })
                        }} label={"Nome"} defaultValue={dataToSend.datiDiFatturazione.nome}/>
                        <CustomTextInput white={true} customDimensions={'w-full h-[40px]'} onChange={(value) => {
                            setDataToSend({
                                ...dataToSend,
                                datiDiFatturazione: {...dataToSend.datiDiFatturazione, cognome: value}
                            })
                        }} label={"Cognome"} defaultValue={dataToSend.datiDiFatturazione.cognome}/>
                    </div>
                    <div className={'w-full h-auto flex gap-x-4  mb-4'}>
                        <CustomTextInput white={true} customDimensions={'w-full h-[40px]'} onChange={(value) => {
                            setDataToSend({
                                ...dataToSend,
                                datiDiFatturazione: {...dataToSend.datiDiFatturazione, email: value}
                            })
                        }} label={"Email"} defaultValue={dataToSend.datiDiFatturazione.email}/>
                        <CustomTextInput white={true} customDimensions={'w-full h-[40px]'} onChange={(value) => {
                            setDataToSend({
                                ...dataToSend,
                                datiDiFatturazione: {...dataToSend.datiDiFatturazione, telefono: value}
                            })
                        }} label={"Telefono"} defaultValue={dataToSend.datiDiFatturazione.telefono}/>
                    </div>
                    <div className={'w-full h-auto flex justify-between items-center'}>
                        <p className={'w-full h-full font-poppins-bold text-2xl text-amber-400 mt-10'}>Dati di
                            Fatturazione</p>
                        <Switch
                            className={'w-full h-full mt-10'}
                            checked={customShipping}
                            styles={{label: {color: 'white'}}}
                            color={'#FFCC00'}
                            label={'Cambia indirizzo di spedizione'}
                            onChange={(value) => {
                                setCustomShipping(value.currentTarget.checked);
                                if (!value.currentTarget.checked) {
                                    setDataToSend({
                                        ...dataToSend,
                                        datiDiSpedizione: {
                                            indirizzo: '',
                                            nome: '',
                                            cognome: '',
                                            note: ''
                                        }
                                    });
                                }
                            }}
                        />
                    </div>
                    <div className={'w-full h-auto flex gap-x-4  mb-4'}>
                        <CustomTextInput
                            white={true}
                            customDimensions={'w-full h-[40px]'}
                            onChange={(value) => {
                                setDataToSend({
                                    ...dataToSend,
                                    datiDiFatturazione: {...dataToSend.datiDiFatturazione, nomeAttivita: value}
                                })
                            }}
                            label={"Attività"}
                            defaultValue={dataToSend.datiDiFatturazione.nomeAttivita}
                        />
                        <CustomTextInput
                            white={true}
                            customDimensions={'w-full h-[40px]'}
                            onChange={(value) => {
                                setDataToSend({
                                    ...dataToSend,
                                    datiDiFatturazione: {...dataToSend.datiDiFatturazione, piva: value}
                                })
                            }}
                            label={"Partita IVA"}
                            defaultValue={dataToSend.datiDiFatturazione.piva}
                        />
                    </div>
                    <div className={'w-full h-auto flex gap-x-4  mb-4'}>
                        <CustomTextInput
                            white={true}
                            customDimensions={'w-full h-[40px]'}
                            onChange={(value) => {
                                setDataToSend({
                                    ...dataToSend,
                                    datiDiFatturazione: {...dataToSend.datiDiFatturazione, sdi_pec: value}
                                })
                            }}
                            label={"SDI/PEC"}
                            defaultValue={dataToSend.datiDiFatturazione.sdi_pec}
                        />
                        <CustomTextInput
                            white={true}
                            customDimensions={'w-full h-[40px]'}
                            label={"Codice Fiscale"}
                            onChange={(value) => {
                                setDataToSend({
                                    ...dataToSend,
                                    datiDiFatturazione: {...dataToSend.datiDiFatturazione, cf: value}
                                })
                            }}
                            defaultValue={dataToSend.datiDiFatturazione.cf}
                        />
                    </div>
                    <div className={'w-full h-auto flex gap-x-4 '}>
                        <GoogleAddressSearch
                            white={true}
                            value={dataToSend.datiDiFatturazione.indirizzo}
                            setNameTextInput={'formatted_address'}
                            label={"Indirizzo"}
                            defaultOpen={false}
                            onPlaceSelect={(value) => {
                                setDataToSend({
                                    ...dataToSend,
                                    datiDiFatturazione: {...dataToSend.datiDiFatturazione, indirizzo: value.formatted_address}
                                })
                            }}
                        />
                        <CustomTextInput
                            white={true}
                            customDimensions={'w-full h-[40px]'}
                            onChange={(value) => {
                                setDataToSend({
                                    ...dataToSend,
                                    datiDiFatturazione: {...dataToSend.datiDiFatturazione, note: value}
                                })
                            }}
                            label={"NOTE"}
                            defaultValue={dataToSend.datiDiFatturazione.note}
                        />
                    </div>
                </div>
                : null
            }
            <Divider size={'md'} color={'#FFCC00'} className={'my-10 mx-20'}/>
            {customShipping ? <div className={'w-full flex flex-col mt-8 gap-y-4'}>
                    <p className={' font-poppins-bold text-2xl text-amber-400 mt-10'}>Dati di spedizione</p>
                    <div className={'w-full h-auto '}>
                        <GoogleAddressSearch
                            white={true}
                            value={dataToSend.datiDiSpedizione.indirizzo}
                            setNameTextInput={'formatted_address'}
                            label={'Nuovo indirizzo di Spedizione'}
                            defaultOpen={false}
                            onPlaceSelect={(value) => {
                                setDataToSend({
                                    ...dataToSend,
                                    datiDiSpedizione: {...dataToSend.datiDiSpedizione, indirizzo: value.formatted_address}
                                });
                            }}/>
                    </div>
                    <div className={'w-full h-auto flex gap-x-4  mb-4'}>
                        <CustomTextInput white={true} customDimensions={'w-full h-[40px]'} onChange={(value) => {
                            setDataToSend({
                                ...dataToSend,
                                datiDiSpedizione: {...dataToSend.datiDiSpedizione, nome: value}
                            });
                        }} label={"Nome"} defaultValue={dataToSend.datiDiSpedizione.nome}/>
                        <CustomTextInput white={true} customDimensions={'w-full h-[40px]'} onChange={(value) => {
                            setDataToSend({
                                ...dataToSend,
                                datiDiSpedizione: {...dataToSend.datiDiSpedizione, cognome: value}
                            });
                        }} label={"Cognome"} defaultValue={dataToSend.datiDiSpedizione.cognome}/>

                        <CustomTextInput
                            white={true}
                            customDimensions={'w-full h-[40px]'}
                            onChange={(value) => {
                                setDataToSend({
                                    ...dataToSend,
                                    datiDiSpedizione: {...dataToSend.datiDiSpedizione, note: value}
                                })
                            }}
                            label={"NOTE"}
                            defaultValue={dataToSend.datiDiSpedizione.note}
                        />
                    </div>
                </div>
                : null
            }
            {customShipping ? <Divider size={'md'} color={'#FFCC00'} className={'my-10 mx-20'}/> : null}

            {selectedRestaurant ? <form className={'w-full mt-20'} id="payment-form" onSubmit={handleSubmit}>
                <div className={'w-full p-10 border-2 flex flex-col px-40'}>
                    <div className={'w-full flex justify-center'}>
                        <p className={'text-white text-2xl'}>Riepilogo Pagamento</p>
                    </div>
                    <div className={'w-full flex justify-between mt-10'}>
                        <p className={'text-white'}>{dataToSend.printerData.name}</p>
                        <p className={'w-24 justify-between flex text-white'}><span className={' flex '}>€</span>{dataToSend.printerData.price.toFixed(2)}</p>

                    </div>

                    <div className={'w-full flex justify-between'}>
                        <p className={'text-white'}>{'IVA 22%'}</p>
                        <p className={'w-24 justify-between flex text-white'}><span className={' flex '}>€</span>{(dataToSend.printerData.price * 22 / 100).toFixed(2)}</p>

                    </div>
                    <Divider size={'md'} color={'#FFCC00'} className={'my-10 '}/>
                    <div className={'w-full flex justify-between'}>
                        <p className={'text-white'}>{'TOTALE'}</p>
                        <p className={'w-24 justify-between flex text-white'}><span className={' flex '}>€</span>{((dataToSend.printerData.price * 22 / 100) + dataToSend.printerData.price).toFixed(2)}</p>

                    </div>
                </div>
                <div className={'w-full mt-10'}>
                    <PaymentElement id="payment-element"/>
                </div>
                <div className={'w-full flex justify-center items-center '}>
                    <div className={'bg-yellow p-4 rounded-xl my-8 cursor-pointer hover:scale-110 duration-500'}>
                        <button className={'w-[300px] h-[30px]'} disabled={isProcessing || !stripe || !elements}
                                id="submit">
                            <span className={'font-poppins-semi-bold'} id="button-text">
                                {isProcessing ? "Processing ... " : "Paga Ora"}
                            </span>
                        </button>
                    </div>
                </div>

            </form> : null}
        </div>
    );
}
