import { useEffect, useState } from "react";
import { getLocalIsInShowcase } from "../network/controllers/WecomePageController";
import { LocalType } from "../types/LocalType";
import { Grid } from "@mantine/core";
import LocalListComponent from "../components/LocalListComponent";
import {isMobile} from "react-device-detect";


export default function ListLocalPage() {
    const [local, setLocal] = useState<LocalType[]>([]);
    var geoData =JSON.parse(localStorage.getItem('geoData') as string) && JSON.parse(localStorage.getItem('geoData') as string).geometry? JSON.parse(localStorage.getItem('geoData') as string):{        geometry:{
            lat:38.142400,
            lng:15.218700,
        },
        radius:5,
    }
    const [reload, setReload] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        dispatchEvent(new CustomEvent("loader", { detail: { isLoading: true } }));
        getLocalIsInShowcase(geoData.geometry.lat, geoData.geometry.lng, geoData.radius, false, (local) => {
            setLocal(local || []);
            dispatchEvent(new CustomEvent("loader", { detail: { isLoading: false } }));
            setIsLoading(false)
        });
    }, [reload]);

    if (isMobile){
        return (
        <div className="w-full h-screen overflow-y-auto pt-[170px] pb-20 p-5">
            {local.length === 0 && !isLoading ? (
                <div className="w-full h-full flex flex-col items-center justify-center">
                    <h1 className=" w-full h-auto text-center font-poppins-bold text-black text-2xl">Nessun locale trovato nelle vicinanze.  </h1>
                    <h1 className=" w-full h-auto text-center font-poppins-regular text-black text-lg pt-2">Cambia località o amplia il filtro di ricerca.  </h1>
                </div>
            ) : (
                <Grid gutter={20}>
                    {local.map((item) => (
                        <Grid.Col className="w-full " span={{ base: 12, xs: 12, md: 12, xl: 6 }} key={item.id}>
                            <LocalListComponent
                                clickable={true}
                                favouriteClickable={true}
                                local={item}
                                showTags={true}
                            />
                        </Grid.Col>
                    ))}
                </Grid>
            )}
        </div>
    );
    }else{
    return (
        <div className="w-full h-screen overflow-y-auto pt-[170px] pb-20 p-5">
            {local.length === 0 && !isLoading ? (
                <div className="w-full h-full flex flex-col items-center justify-center">
                    <h1 className="font-poppins-bold text-black text-3xl">Nessun locale trovato nelle vicinanze. </h1>
                    <h1 className=" w-full h-auto text-center font-poppins-regular text-black text-lg pt-2">Cambia
                        località o amplia il filtro di ricerca. </h1>

                </div>
            ) : (
                <Grid gutter={20}>
                    {local.map((item) => (
                        <Grid.Col className="w-full " span={{base: 12, xs: 12, md: 12, xl: 6 }} key={item.id}>
                            <LocalListComponent
                                clickable={true}
                                favouriteClickable={true}
                                local={item}
                                showTags={true}
                            />
                        </Grid.Col>
                    ))}
                </Grid>
            )}
        </div>
    );
}
}
