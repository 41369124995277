import SettingsAdminCardComponent from "../../components/SettingsAdminCardComponent";
import settingsAccountIcon from '../../assets/settings-account-icon.svg';
import iconSettingCardArrow from '../../assets/icon-setting-card-arrow.svg';
import iconSettingKey from '../../assets/icon-setting-key.svg';
import {Drawer, Grid} from "@mantine/core";
import React, {useEffect, useState} from "react";
import AccountDrawerComponent from "../../components/AccountDrawerComponent";
import PasswordDrawerComponent from "../../components/PasswordDrawerComponent";
import {getLicenceFromLicenseId} from "../../network/controllers/admin/AdminController";
import {LicenseType} from "../../types/LicenseType";
import {formatStringDuration} from "../../utils/StringUtils";
import GenericTooltip from "../../components/generic/GenericTooltip";
import infoIcon from "../../assets/info-tooltip-icon.svg";
import {isMobile} from "react-device-detect";
import CustomButton from "../../components/CustomButton";
import {useNavigate} from "react-router-dom";

export default function SettingsAdminPage() {
    const [openDreawer, setOpenDreawer] = useState({open: false, dimension: '30%'});
    const [contenentDrawer, setContenentDrawer] = useState(<div></div>);
    const [license, setLicence] = useState<LicenseType | null>(null);
    useEffect(() => {
        getLicenceFromLicenseId((response) => {
            setLicence(response)
        })
    }, []);
    var navigator = useNavigate();
    function retrieveStatus(status: string): React.JSX.Element {
        if (status === 'ACTIVE') {
            return <div className={'w-5 h-5 bg-lime-400 rounded-full ml-2 border-[1px] border-white'}></div>
        } else if (status === 'SUSPENDED') {
            return <div className={'w-5 h-5 bg-yellow rounded-full ml-2 border-[1px] border-white'}></div>
        } else {
            return <div className={'w-5 h-5 bg-red-600 rounded-full ml-2 border-[1px] border-white'}></div>
        }
    }

    if (isMobile) {

        return (
            <div className={'w-full h-full flex flex-col items-center pt-[130px]'}>
                <Drawer styles={{content: {backgroundColor: 'black'}, header: {backgroundColor: 'black'}}}
                        position={'bottom'} size={'full'} opened={openDreawer.open} onClose={() => {
                    setOpenDreawer({...openDreawer, open: false});
                }}>
                    {contenentDrawer}
                </Drawer>

                <SettingsAdminCardComponent title={'Account'} text={'Clicca qui per gestire i tuoi dati personali'}
                                            image={settingsAccountIcon} arrow={iconSettingCardArrow} onClick={() => {
                    setOpenDreawer({...openDreawer, open: true});
                    setContenentDrawer(<AccountDrawerComponent/>)
                }}/>
                {/* <SettingsAdminCardComponent title={'Coperto'} text={'Imposta il costo dei coperti'}
                                        image={iconSettingMoney} arrow={iconSettingCardArrow} onClick={() => {
                setOpenDreawer(true)
                setContenentDrawer(<CoverageDrawerComponent/>)
            }}/>*/}

                <SettingsAdminCardComponent title={'Cambio Password'} text={'Imposta la tua nuova password'}
                                            image={iconSettingKey} arrow={iconSettingCardArrow} onClick={() => {
                    setOpenDreawer({...openDreawer, open: true});
                    setContenentDrawer(<PasswordDrawerComponent/>)
                }}/>
                <div className={'flex w-full h-[220px] bg-black rounded-3xl'} onClick={() => {
                    setOpenDreawer({open: true, dimension: 'full'})
                    setContenentDrawer(<div className={`w-full h-full justify-center items-center`}>
                        <div
                            className={`w-auto h-auto bg-white rounded-2xl  flex justify-center  gap-x-5 py-10`}>
                            <div
                                className={`w-auto flex flex-col items-center justify-center p-5 rounded-xl gap-y-2 space-y-2  h-min `}>

                                <div
                                    className={`w-auto h-min flex flex-col items-center justify-center p-5 rounded-xl gap-y-2 space-y-2 border-[1px] border-black bg-amber-200 mb-4 shadow-black shadow-lg `}>
                                    <img alt={''} className=' w-auto pt-5 ' src={license?.image}/>
                                    <h1 className={'font-poppins-bold text-3xl'}>{license?.namePackage}</h1>
                                    <h1 className={'text-3xl font font-poppins-regular '}>{license?.nameLicense}</h1>
                                    <h1 className={'font-poppins-semi-bold text-2xl'}>{'€' + license?.price + ' + iva / ' + formatStringDuration(license?.duration!)}</h1>
                                </div>
                                <CustomButton small={true} title={'Rinnova abbonamento'} onClick={()=>{
                                    navigator('/restaurant/rinnova')
                                }} enable={true} isLoading={false} />
                                <Grid>

                                    {license!.listService.sort().map((service) => (
                                        <Grid.Col span={{lg: 12, base: 12, sm: 12, xl: 12}}>
                                            <div
                                                className={`flex text-start w-auto h-auto  pt-1`}>
                                                <div className={'w-auto flex '}>
                                                    <GenericTooltip txt={service.description}
                                                                    innerHtml={<img alt={''} className={'w-5 h-5 ml-1'}
                                                                                    src={infoIcon}/>}/>
                                                    <h1 className={' text-sm pl-2 whitespace-normal'}>{service.title}</h1>

                                                </div>
                                            </div>
                                        </Grid.Col>

                                    ))}
                                </Grid>

                            </div>
                        </div>
                    </div>)
                }
                }>

                    <div className={'w-full h-full flex p-5  '}>
                        <div className={'flex flex-col justify-around'}>
                            <div className={'w-full h-auto text-white text-2xl font-poppins-bold'}>Informazioni
                                Abbonamento
                            </div>
                            <div className={'w-auto h-auto text-white flex flex-col text-md font-poppins-regular'}>
                                <h1>{'Nome Pacchetto : ' + license?.namePackage}</h1>
                                <h1>{'Nome Licenza : ' + license?.nameLicense}</h1>
                                <div className={'w-full flex'}>
                                    <h1>{'Stato Abbonamento : '}</h1>
                                    {retrieveStatus(license?.status!)}
                                </div>
                                <h1>{'Scadenza Abbonamento : ' + new Date(license?.expirationDate!).toLocaleDateString()}</h1>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className={'w-full h-full flex gap-x-5  justify-start pl-10 pt-[130px]'}>
                <Drawer styles={{content: {backgroundColor: 'black'}, header: {backgroundColor: 'black'}}}
                        position={'right'} size={openDreawer.dimension} opened={openDreawer.open} onClose={() => {
                    setOpenDreawer({...openDreawer, open: false});
                }}>
                    {contenentDrawer}
                </Drawer>

                <SettingsAdminCardComponent title={'Account'} text={'Clicca qui per gestire i tuoi dati personali'}
                                            image={settingsAccountIcon} arrow={iconSettingCardArrow} onClick={() => {
                    setOpenDreawer({...openDreawer, open: true});
                    setContenentDrawer(<AccountDrawerComponent/>)
                }}/>
                {/* <SettingsAdminCardComponent title={'Coperto'} text={'Imposta il costo dei coperti'}
                                        image={iconSettingMoney} arrow={iconSettingCardArrow} onClick={() => {
                setOpenDreawer(true)
                setContenentDrawer(<CoverageDrawerComponent/>)
            }}/>*/}

                <SettingsAdminCardComponent title={'Cambio Password'} text={'Imposta la tua nuova password'}
                                            image={iconSettingKey} arrow={iconSettingCardArrow} onClick={() => {
                    setOpenDreawer({...openDreawer, open: true});
                    setContenentDrawer(<PasswordDrawerComponent/>)
                }}/>
                <div className={'flex w-[400px] h-[250px] bg-black rounded-3xl'} onClick={() => {
                    setOpenDreawer({open: true, dimension: '40%'})
                    setContenentDrawer(<div className={`w-full h-full   justify-center items-center px-40 `}>
                        <div
                            className={`w-auto h-auto bg-white rounded-2xl  flex justify-center overflow-x-auto gap-x-5  py-10`}>
                            <div
                                className={`w-auto flex flex-col items-center justify-center p-5 rounded-xl gap-y-2 space-y-2  h-min `}>
                                <img alt={''} className=' w-auto pt-5 ' src={license?.image}/>
                                <h1 className={'font-poppins-bold text-3xl'}>{license?.namePackage}</h1>
                                <h1 className={'text-3xl font font-poppins-regular '}>{license?.nameLicense}</h1>
                                <h1 className={'font-poppins-semi-bold text-2xl'}>{'€' + license?.price + ' + iva / ' + formatStringDuration(license?.duration!)}</h1>
                                <CustomButton title={'Rinnova abbonamento'} onClick={()=>{
                                    navigator('/restaurant/rinnova')
                                }} enable={true} isLoading={false} small={true}/>
                                <Grid>
                                    {license!.listService.sort().map((service) => (
                                        <Grid.Col span={{lg: 12, base: 12, sm: 12, xl: 12}}>
                                            <div
                                                className={`flex justify-center  items-center w-auto h-auto pt-1`}>
                                                <div className={'w-auto flex items-center justify-center '}>
                                                    <GenericTooltip txt={service.description}
                                                                    innerHtml={<img alt={''} className={'w-5 h-5 ml-1'}
                                                                                    src={infoIcon}/>}/>
                                                    <h1 className={'pl-2 text-nowrap'}>{service.title}</h1>

                                                </div>
                                            </div>
                                        </Grid.Col>

                                    ))}
                                </Grid>

                            </div>
                        </div>
                    </div>)
                }
                }>

                    <div className={'w-full h-full flex p-5  '}>
                        <div className={'flex flex-col justify-around'}>
                            <div className={'w-full h-auto text-white text-2xl font-poppins-bold'}>Informazioni
                                Abbonamento
                            </div>
                            <div className={'w-auto h-auto text-white flex flex-col text-md font-poppins-regular'}>
                                <h1>{'Nome Pacchetto : ' + license?.namePackage}</h1>
                                <h1>{'Nome Licenza : ' + license?.nameLicense}</h1>
                                <div className={'w-full flex'}>
                                    <h1>{'Stato Abbonamento : '}</h1>
                                    {retrieveStatus(license?.status!)}
                                </div>
                                <h1>{'Scadenza Abbonamento : ' + new Date(license?.expirationDate!).toLocaleDateString()}</h1>
                                <h1>{'Durata Licenza : ' + license?.duration}</h1>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
