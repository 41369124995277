import React, { useEffect, useState } from 'react';
import './App.css';
import './index.css';
import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/dates/styles.css';
import "@mantine/carousel/styles.css";
import RouteLogin from "./routing/RouteLogin";
import {
    notificationFail,
    notificationSuccess,
    notificationWarning,
    FailNotification,
    SuccessNotification,
    WarningNotification
} from "./utils/NotificationUtils";
import {Dialog, Group, LoadingOverlay} from "@mantine/core";
import QrWebMenuPage from "./pages/admin/QrWebMenuPage";
import { LoginResponseType } from "./types/LoginResponseType";
import { getUserInfo } from "./network/controllers/LoginApiController";
import {useDisclosure, useFavicon} from "@mantine/hooks";
import AddRestaurantSellerPage from "./pages/seller/add-restaurant/AddRestaurantSellerPage";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import logoSazio from "../src/assets/logo-mai-sazio.svg"
import Footer from "./components/generic/Footer";
import ImageLogoMenus from "./components/ImageLogoMenus";
import GoogleAddressSearch from './components/GoogleAddressSearch';
import ChatBotComponent from "./components/chatbot/ChatBotComponent";
import closeIcon from "./assets/close-icon.svg"

function App() {
    const firebaseConfig = {
        apiKey: "AIzaSyCFlHoToWeWY7e52EEebSQ6KiNmeuoBeeE",
        authDomain: "maisazio-web.firebaseapp.com",
        projectId: "maisazio-web",
        storageBucket: "maisazio-web.appspot.com",
        messagingSenderId: "420308238498",
        appId: "1:420308238498:web:15fcf03470a1b44a3f57fb",
        measurementId: "G-D835RSWXMF"
    };


    useEffect(() => {
        if (!localStorage.getItem('geoData')){
            //default barcellona pozzo di gotto
            localStorage.setItem('geoData', JSON.stringify({"geometry":{"lat":38.1471908,"lng":15.2129391},"radius":1}));
            localStorage.setItem('formatted_address', '98051 Barcellona Pozzo di Gotto ME, Italia');
        }
    }, []);

    const [isFooterVisible, setIsFooterVisible] = useState(!window.location.pathname.includes('admin') &&  !window.location.pathname.includes('registrati'));


    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);

    useFavicon(logoSazio);

    const [page, setPage] = useState(<div></div>);
    const [show, setShow] = useState("undefined");
    const [loading, setLoading] = useState(false);
    window.addEventListener('loader', (e) => {
        // @ts-ignore
        setLoading(e.detail.isLoading)
    });


    window.addEventListener('footerVisible', (e) => {
        // @ts-ignore
        setIsFooterVisible(e.detail.isVisible)
    });

    const userData = JSON.parse(localStorage.getItem('userData')!) as LoginResponseType;

    useEffect(() => {
        if (userData) {
            getUserInfo(userData.email, (response) => {
                localStorage.setItem('userData', JSON.stringify(response));
                dispatchEvent(new CustomEvent("userInfo"));
            });
        }
    }, []);

    document.addEventListener("showSuccessNotification", function () {
        setShow('success');
    });
    document.addEventListener("showFailNotification", function () {
        setShow('fail');
    });
    document.addEventListener("showWarningNotification", function () {
        setShow('warning');
    });
    document.addEventListener('hideNotification', function () {
        setShow('undefined');
    });
    const [opened, { toggle, close }] = useDisclosure(false);

    return (
        <div className={'w-screen h-screen '}>
            <div className={'w-full h-full'}>
                <Dialog classNames={{
                    root:'bg-transparent shadow-none'
                }} color={'#FFCC00'} position={{ top: 75, right:0 }} opened={!localStorage.getItem('closedGeodata')} onClose={()=>{
                    localStorage.setItem('closedGeodata','true')
                    window.location.reload()
                }} size="xl" radius="md">
                    <Group align="flex-end">
                        <div className={'w-full h-auto bg-black flex flex-col py-10 rounded-xl '}>
                            <div className={'w-full h-full flex  justify-between '}>
                                <div className={`w-full max-h-[60px] pl-10 pr-5 mt-[-10px]  flex justify-between`}>
                                    <ImageLogoMenus/>
                                    <div className={'cursor-pointer'} onClick={() => {
                                        localStorage.setItem('closedGeodata', 'true')
                                        window.location.reload()
                                    }}>
                                        <img className={'invert hover:invert-0'} src={closeIcon}/>
                                    </div>
                                </div>

                            </div>
                            <div className={`w-full px-10  flex flex-col justify-center items-center  `}>
                                <div className={'w-full flex flex-col mt-6'}>
                                    <h1 className={`text-white my-1 text-nowrap font-poppins-bold text-md`}><strong>Trova
                                        il ristorante più vicino a te</strong></h1>
                                    <h1 className={`text-white text-sm my-1 font-poppins-semi-bold mb-2`}>Inserisci il
                                        tuo indirizzo o la tua città</h1>
                                </div>
                                <div className={'w-full h-full flex '}>
                                    <GoogleAddressSearch defaultOpen={false}
                                                         inputDimensions={'w-full h-[60px]'}
                                                         suggestionDimensions={'w-full '}
                                                         setNameTextInput={'formatted_address'} width={'100px'}
                                                         onPlaceSelect={(v) => {
                                                             localStorage.setItem('closedGeodata','true')
                                                             localStorage.setItem('geoData', JSON.stringify({
                                                                 geometry: v.geometry.location,
                                                                 radius: 5
                                                             }))

                                                             localStorage.setItem('formatted_address', JSON.stringify(v.formatted_address))
                                                             window.location.reload()
                                                         }}/>
                                </div>

                            </div>
                        </div>
                    </Group>
                </Dialog>
                <LoadingOverlay
                    visible={loading}
                    zIndex={1000}
                    overlayProps={{radius: 'sm', blur: 2}}
                    loaderProps={{color: 'yellow', type: 'bars', size: 'xl'}}
                />
                {window.location.pathname.includes('qrMenu') ?
                    <QrWebMenuPage/> : window.location.pathname.includes('payment') ?
                        <RouteLogin/> : window.location.pathname.includes('registrati') ? <AddRestaurantSellerPage isRenew={false}/> :
                            <RouteLogin/>}

          {!window.location.pathname.includes('admin') && !window.location.pathname.includes('login') && !window.location.pathname.includes('registration') && !window.location.pathname.includes('rinnova') ?
                    <ChatBotComponent key={Math.random()} /> : null}
                {isFooterVisible && !window.location.pathname.includes('qr') && !window.location.pathname.includes('rinnova') && !window.location.pathname.includes('registr') ?
                    <Footer key={Math.random()} /> : null}

            </div>
            {show === 'warning' && notificationWarning(WarningNotification.detail.title, WarningNotification.detail.warningYes)}
            {show === 'success' && notificationSuccess(SuccessNotification.detail.title, SuccessNotification.detail.text, SuccessNotification.detail.time)}
            {show === 'fail' && notificationFail(FailNotification.detail.title, FailNotification.detail.text, FailNotification.detail.time)}

        </div>
    );
}

export default App;
