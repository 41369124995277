import iconService from "../assets/service-icon-detail.svg";
import {LocalType} from "../types/LocalType";
import {MultiSelect} from "@mantine/core";
import GenericMultiselect from "./generic/GenericMultiselect";
import {useEffect, useState} from "react";
import {getTag} from "../network/controllers/admin/AdminController";
import {updateTag} from "../network/controllers/RestaurantsControllers";
import {TagType} from "../types/TagType";

export default function ServiceDetailPageAdmin(props:{local:LocalType}){
    const [tagList, setTagList] = useState<{ value:string, label:string }[]>([]);
    const [tagListSelected, setTagListSelected] = useState<string[]>([]);

    useEffect(() => {
        getTag((res)=>{
            var listTag:{ value:string, label:string }[] = []
            res.forEach(item=>{
                listTag.push({value:JSON.stringify(item), label:item.tag})
            })
            setTagList(listTag)
        })
    }, []);

    useEffect(() => {
        var listTag:string[] = []
        props.local.tags.forEach(item=>{
            listTag.push(JSON.stringify(item))
        })
        setTagListSelected(listTag)
    }, [tagList]);


    useEffect(() => {
    }, [tagListSelected]);

    return (
        <div className={'w-full '}>
            <div className={'w-full h-14 bg-black p-2 rounded-xl'}>
                <div className={'flex items-center h-full'}>
                    <img src={iconService} className={'w-8'}/>
                    <h1 className={'font-bold text-amber-400 text-lg ml-2'}>Servizi (max 8) </h1>
                </div>
            </div>
            <div className={'w-full flex flex-col  justify-center  px-4 pt-4'}>
                <div className={'w-[310px] h-auto'}>
                    {tagList.length>0?<GenericMultiselect searchable={true} maxValues={8} label={''}
                                                          placeholder={'scegli i servizi più adatti al tuo locale!'}
                                                          data={tagList} defaultValue={tagListSelected} onSelect={(d)=>{
                        var localTag:TagType[] = []
                        d.forEach((item)=>{
                            localTag.push(JSON.parse(item));
                        })
                        updateTag(localTag, props.local.id, (res)=>{
                            localStorage.setItem('selectedRestaurant', JSON.stringify(res))
                        })
                    }}/>:null}
                </div>


            </div>
        </div>
    )
}
