import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Popover} from "@mantine/core";
import {isTablet} from "../../utils/WindowDimensionUtils";
import {getLicenceFromLicenseId, getRestaurants} from "../../network/controllers/admin/AdminController";
import {GetRestaurantResponseType} from "../../types/GetRestaurantResponseType";
import {LocalType} from "../../types/LocalType";
import {connectMQTT} from "../../utils/MqttUtility";
import iconSwitch from "../../assets/switch-icon.svg"
import GenericTooltip from "../../components/generic/GenericTooltip";
import {showFailNotification} from "../../utils/NotificationUtils";
import PaymentPage from "../../components/PaymentPage";
import {Buffer} from "buffer";
import {isMobile} from "react-device-detect";


export default function RestaurantSelect() {
    const [popOverStatus, setPopOverStatus] = useState(false)
    let navigate = useNavigate()
    const [restaurantList, setRestaurantList] = useState<GetRestaurantResponseType[]>([])


    function formatCustomDate(date:Date) {
        const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const day = days[date.getUTCDay()];
        const month = months[date.getUTCMonth()];
        const dayOfMonth = date.getUTCDate().toString().padStart(2, '0');
        const year = date.getUTCFullYear();
        const hours = date.getUTCHours().toString().padStart(2, '0');
        const minutes = date.getUTCMinutes().toString().padStart(2, '0');
        const seconds = date.getUTCSeconds().toString().padStart(2, '0');
        const timeZone = "CEST";
        return `${day} ${month} ${dayOfMonth} ${hours}:${minutes}:${seconds} ${timeZone} ${year}`;
    }

    useEffect(() => {
        getRestaurants((response) => {
            if (response.length>0){
                setRestaurantList(response)
            }else{
                showFailNotification('Errore','Non hai nessun ristorante abbinato alla tua utenza',4000)
            }
        })
    }, []);
    useEffect(() => {
        var local = JSON.parse(localStorage.getItem('selectedRestaurant') as string) as LocalType
        if (local) connectMQTT('WEB-' + local.nameRestaurant.replace(/ /g, "-").toUpperCase() + '-' + Math.random().toString().substring(2, 7))
    }, []);

    return (
        <Popover width={isMobile?400:600} position="bottom-end" withArrow shadow="md" onOpen={() => {
            setPopOverStatus(true)
        }} onClose={() => setPopOverStatus(false)}>
            <Popover.Target>
                <div className={` flex row gap-x-2.5 items-start w-auto cursor-pointer border-[1px]  border-transparent ${popOverStatus ? 'scale-110' : ''} hover:scale-110 duration-300 p-2 mr-5 `}>
                    <img alt={''} className={`${isMobile?'w-[15px] h-[15px]':'w-[25px] h-[25px]'} `} src={iconSwitch}/>
                    <p className={` ${isMobile ? 'text-xs' : ''} text-center whitespace-nowrap truncate  text-white font-poppins-bold`}>{localStorage.getItem('selectedRestaurant') ? JSON.parse(localStorage.getItem('selectedRestaurant')!).nameRestaurant : 'Nessun locale selezionato'}</p>
                </div>
            </Popover.Target>
            <Popover.Dropdown classNames={{dropdown: 'dropDownLogin'}}>
                <div className={'overflow-y-auto flex flex-col items-center justify-center py-5 gap-y-2'}>
                    {restaurantList.length > 0 ? restaurantList.map((restaurant) => (
                        restaurant.status !== 'ACTIVE' ?
                            <GenericTooltip txt={'Il locale selezionato non è stato ancora abilitato, controlla la tua mail o clicca qui per attivare!'} innerHtml={
                                <p key={restaurant.id} onClick={()=>{
                                    getLicenceFromLicenseId((license)=>{
                                        var obj={ammountCent:license.price,dataExtra:{},description:license.description==null?'':license.description,stripeId:restaurant.stripeId,nameRestaurant:restaurant.nameRestaurant}
                                        const auth =Buffer.from(JSON.stringify(obj)).toString('base64');
                                        navigate(`/payment?data=${auth}&date=${formatCustomDate(new Date())}&license=${license.idLicense}&package=${license.idPackage}`)
                                    },restaurant.licenseActive)
                                }} className={`${isMobile?'text-sm':''} p-1 w-full  text-center opacity-50 font-poppins-regular whitespace-nowrap truncate cursor-pointer hover:scale-110 duration-500 ${localStorage.getItem('selectedRestaurant') ? JSON.parse(localStorage.getItem('selectedRestaurant')!).nameRestaurant === restaurant.nameRestaurant ? 'text-black' : 'text-black' : 'text-black'}`}
                                >{restaurant.nameRestaurant}</p>
                            }
                            /> : <p key={restaurant.id}
                                    className={`${isMobile?'text-sm':''} p-1 w-full text-center font-poppins-regular whitespace-nowrap cursor-pointer truncate hover:scale-105 duration-500 ${localStorage.getItem('selectedRestaurant') ? JSON.parse(localStorage.getItem('selectedRestaurant')!).nameRestaurant === restaurant.nameRestaurant ? 'text-black' : 'text-black' : 'text-black'}`}
                                    onClick={() => {
                                        localStorage.setItem('selectedRestaurant', JSON.stringify(restaurant))
                                        navigate('/admin')
                                        window.location.reload()
                                    }}
                            >{restaurant.nameRestaurant}</p>

                    )) : null}
                    <p className={'font-poppins-bold whitespace-nowrap cursor-pointer hover:scale-110 duration-500 '}
                       onClick={() => navigate('/restaurant')}>Aggiungi un nuovo Ristorante</p>
                </div>
            </Popover.Dropdown>
        </Popover>
    )
}
