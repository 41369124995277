import RouteAdmin from "../../routing/RouteAdmin";
import HeaderAdminComponent from "./HeaderAdminComponent";
import {getDetailRestaurants} from "../../network/controllers/RestaurantsControllers";
import {LocalType} from "../../types/LocalType";
import React, {useEffect, useState} from "react";
import {getLicenceFromLicenseId, getRestaurants} from "../../network/controllers/admin/AdminController";
import {LoginResponseType} from "../../types/LoginResponseType";
import {useNavigate} from "react-router-dom";
import {gestioneLocaliArray, myAccountArray, ordiniArray, routeListArray} from "../../utils/MenuUtils";
import {PackEnum} from "../../enums/PackEnum";
import failureIcon from "../../assets/payment/payment-failure-icon.svg";
import {isMobile} from "react-device-detect";
import CustomButton from "../../components/CustomButton";


export default function RootPageAdmin() {
    var userData = JSON.parse(localStorage.getItem('userData')!) as LoginResponseType
    const [isFinish, setIsFinish] = useState(false);
    const [routeList, setRouteList] = useState<any[]>([]);
    const [manageLocalArray, setManageLocalArray] = useState<any[]>([]);
    const [orderArray, setOrderArray] = useState<any[]>([]);
    const [accountArray, setAccountArray] = useState<any[]>([]);

    const navigate = useNavigate();


    useEffect(() => {
        if (userData && userData.role.includes('ADMIN')) {
            if (localStorage.getItem('selectedRestaurant')) {
                getDetailRestaurants((JSON.parse(localStorage.getItem('selectedRestaurant') as string) as LocalType).id, (response) => {
                    // @ts-ignore
                    if (response.code!== undefined && response.code!==200){
                        //se la dettaglio va in fail il locale non esiste più o è disattivato
                        // quindi lo rimuovo dalle local e refresh della pagina per aggiornare i dati
                        localStorage.removeItem('selectedRestaurant');
                        window.location.reload();
                    }else{
                        if (response.status==='SUSPENDED'){
                            localStorage.removeItem('selectedRestaurant');
                            setIsFinish(true)
                        }else{
                            localStorage.setItem('selectedRestaurant', JSON.stringify(response));
                            getLicenceFromLicenseId((license) => {
                                setManageLocalArray(gestioneLocaliArray.filter((item) => item.pack.includes(PackEnum.retrieveFromString(license.namePackage))));
                                setOrderArray(ordiniArray.filter((item) => item.pack.includes(PackEnum.retrieveFromString(license.namePackage))));
                                setAccountArray(myAccountArray.filter((item) => item.pack.includes(PackEnum.retrieveFromString(license.namePackage))));
                                setRouteList(routeListArray.filter((item) => item.pack.includes(PackEnum.retrieveFromString(license.namePackage))));

                                setIsFinish(true);

                            })
                        }

                    }

                })
            } else {

                getRestaurants((response) => {
                    if (localStorage.getItem('selectedRestaurant')) {
                        getLicenceFromLicenseId((license) => {
                            setManageLocalArray(gestioneLocaliArray.filter((item) => item.pack.includes(PackEnum.retrieveFromString(license.namePackage))));
                            setOrderArray(ordiniArray.filter((item) => item.pack.includes(PackEnum.retrieveFromString(license.namePackage))));
                            setAccountArray(myAccountArray.filter((item) => item.pack.includes(PackEnum.retrieveFromString(license.namePackage))));
                            setRouteList(routeListArray.filter((item) => item.pack.includes(PackEnum.retrieveFromString(license.namePackage))));
                            setIsFinish(true)
                        })
                    } else {
                        debugger
                        setIsFinish(true)
                    }

                })
            }
        } else {
            //torna alla homepage se non è un admin o non è loggato e prova a interrogare il path
            navigate('/welcome')
        }

    }, []);


    if (isFinish) {
        return (
            <div className={'h-full'}>
                <HeaderAdminComponent localeArray={manageLocalArray} ordiniArray={orderArray}
                                      myAccountArray={accountArray}/>
                <div className={`h-full ${isMobile ? 'px-3 pb-5':'px-10 pb-10'}`}>
                    {localStorage.getItem('selectedRestaurant') ? <RouteAdmin routeList={routeList}/> :
                        <div className={'w-full h-full flex flex-col items-center justify-center'}>
                            <img alt={''} className={'w-[65%] h-[65%]'}
                                 src={failureIcon}/>
                                <h1 className={'items-center text-2xl font-poppins-semi-bold pt-24'}>
                                   Nessuno dei tuoi locali è attivo 😔. Clicca il menu in alto a destra per procedere con il rinnovo.
                                </h1>
                        </div>
                    }
                </div>
            </div>
        )
    } else {
        return (
            <div className={'h-full'}></div>
        )
    }


}
